export default [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'Update Date',
        field: 'updateDate',
        filter: 'date',
    },
    {
        headerName: 'Is Deleted',
        field: 'isDeleted',
    },
    {
        headerName: 'Class Period',
        field: 'classPeriod',
    },
    {
        headerName: 'Course ID',
        field: 'courseId',
    },
    {
        headerName: 'Course Section ID',
        field: 'courseSectionId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Days Class Meets',
        field: 'daysClassMeets',
    },
    {
        headerName: 'Grading Period ID',
        field: 'gradingPeriodId',
    },
    {
        headerName: 'Is Attendance Enabled',
        field: 'isAttendanceEnabled',
    },
    {
        headerName: 'Is Primary Teacher',
        field: 'isPrimaryTeacher',
    },
    {
        headerName: 'Room Number',
        field: 'roomNumber',
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
    },
    {
        headerName: 'Section Number',
        field: 'sectionNumber',
    },
    {
        headerName: 'SIS ID',
        field: 'sISId',
    },
    {
        headerName: 'Staff Role',
        field: 'staffRole',
    },
    {
        headerName: 'Staff ID',
        field: 'staffId',
    },
    {
        headerName: 'User End Date',
        field: 'userEndDate',
    },
    {
        headerName: 'User Start Date',
        field: 'userStartDate',
    },
]
